@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 5px 20px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNiIgaGVpZ2h0PSI2Ij4KPHJlY3Qgd2lkdGg9IjYiIGhlaWdodD0iNiIgZmlsbD0iI2VlZWVlZSI+PC9yZWN0Pgo8ZyBpZD0iYyI+CjxyZWN0IHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IiNlNmU2ZTYiPjwvcmVjdD4KPHJlY3QgeT0iMSIgd2lkdGg9IjMiIGhlaWdodD0iMiIgZmlsbD0iI2Q4ZDhkOCI+PC9yZWN0Pgo8L2c+Cjx1c2UgeGxpbms6aHJlZj0iI2MiIHg9IjMiIHk9IjMiPjwvdXNlPgo8L3N2Zz4=');
  font-family: 'Open Sans';
  font-weight: 300;
}

h2 {
  margin-bottom: 30px;
  color: #4679bd;
  font-weight: 400;
  text-align: center;
}

p.footer {
  margin-bottom: 20px;
  color: #999999;
  font-size: 18px;
  text-align: center;
}

/* -----------------------------------------------
 * Timeline
 * --------------------------------------------- */
.timeline {
  list-style: none;
  padding: 10px 0;
  position: relative;
  font-weight: 300;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 2px;
  background: #181818;
  left: 50%;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 20px;
  position: relative;
  width: 50%;
  float: left;
  clear: left;
}
.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: calc(100% - 25px);
  width: -moz-calc(100% - 25px);
  width: -webkit-calc(100% - 25px);
  float: left;
  //   border: 1px solid #dcdcdc;
  //   background: #ffffff;
  border: 1px solid #1151737a;
  background: #181818;
  position: relative;
  color: #dcdcdc;
}
.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #dcdcdc;
  border-right: 0 solid #dcdcdc;
  border-bottom: 15px solid transparent;
  content: ' ';
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #181818;
  border-right: 0 solid #181818;
  border-bottom: 14px solid transparent;
  content: ' ';
}
.timeline > li > .timeline-badge {
  //   color: #ffffff;
  width: 24px;
  height: 24px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -12px;
  z-index: 100;

  color: #dcdcdc;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-badge > a {
  color: #ffffff !important;
}
.timeline-badge a:hover {
  color: #dcdcdc !important;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-heading h4 {
  font-weight: 400;
  padding: 0 15px;
  color: #ffd700;
}
.timeline-body > p,
.timeline-body > ul {
  padding: 10px 15px;
  margin-bottom: 0;
}
.timeline-footer {
  padding: 5px 15px;
  background-color: #181818;
}
.timeline-footer p {
  margin-bottom: 0;
}
.timeline-footer > a {
  cursor: pointer;
  text-decoration: none;
}
.timeline > li.timeline-inverted {
  float: right;
  clear: right;
}
.timeline > li:nth-child(2) {
  margin-top: 60px;
}
.timeline > li.timeline-inverted > .timeline-badge {
  left: -12px;
}
.no-float {
  float: none !important;
}
@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }
  ul.timeline > li {
    margin-bottom: 0px;
    position: relative;
    width: 100%;
    float: left;
    clear: left;
  }
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 65px);
    width: -moz-calc(100% - 65px);
    width: -webkit-calc(100% - 65px);
  }
  ul.timeline > li > .timeline-badge {
    left: 28px;
    margin-left: 0;
    top: 16px;
  }
  ul.timeline > li > .timeline-panel {
    float: right;
  }
  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  .timeline > li.timeline-inverted {
    float: left;
    clear: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-badge {
    left: 28px;
  }
}

.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  .image-box {
    position: relative;
    flex: 1 1 20%;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 10px);

    .portfolio-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 10px 20px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 1)
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #ffd700;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #ffd700;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, #ffd700, #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }
}
